@use './variables';


//desktop viewport mixin, refer to vriables for breakpoints
@mixin desktop {
    @media (min-width: 1000px){
        @content;
    }
}

//tablet viewport mixin, refer to vriables for breakpoints
@mixin tablet {
    @media (min-width: 300px){
        @content;
    }
}

//styling for flexbox positioning
@mixin flex($align: normal, $justify:normal, $direction:row, $wrap: nowrap) {
    display: flex;
    justify-content: $justify;
    align-items: $align;
    flex-direction:$direction;
    flex-wrap:$wrap;
}


//styling for fonts
@mixin font($size:2rem, $weight: 700, $line:normal, $spacing:normal) {
    font-size:$size;
    font-weight:$weight;
    line-height: $line;
    letter-spacing: $spacing;
}

