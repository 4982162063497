@use './styles/fonts' as *;
@use './styles/mixins' as *;
@use './styles/variables' as *;


body {
    box-sizing: border-box;
    margin:0;
    font-family: $font;
    // overflow-x: hidden;
    background: $primary-background;
}

.main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    

    &__logo{
        height: 22.5rem;
        width: 22.5rem;
        margin: 6.875rem 0 6.875rem 0;
        @include desktop {
            height: 40rem;
            width: 40rem;
            margin: 3.75rem;
        }
    }

    &__text{
        margin:0;
        color:$primary-text;
        font-size: 3rem;
        @include desktop {
            
            font-size: 3.75rem;
            
        }
        
    }
}




